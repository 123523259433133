//滑鼠右鍵
document.oncontextmenu = function (e) { return false; }
// 解決 IOS無法Hover
document.body.addEventListener('touchstart', function () { }, false);
// 決解FontAwesome 5 ICON 存問題
document.addEventListener('turbolinks:load', function () {
    FontAwesome.dom.i2svg()
})
//=====footer置底自動計算RWD可用=====
$(document).ready(function () {
    $(window).resize(function () {
        var footerHeight = $('footer').outerHeight();
        var stickFooterPush = $('.push').height(footerHeight);
        $('#contents').css({ 'marginBottom': '-' + footerHeight + 'px' });
    });
    $(window).resize();
});

// ===== Scroll to Top 向上案鈕====
$(window).scroll(function () {
    if ($(this).scrollTop() >= 400) {
        $('#top').fadeIn("fast");
    } else {
        $('#top').fadeOut("fast");
    }
});
$('#top').click(function () {
    $('body,html').animate({
        scrollTop: 0
    }, 600);
});

/*!
* Bootstrap 4 dropdown navbar 多層選單
*
*/
$(document).ready(function () {
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
        var $el = $(this);
        var $parent = $(this).offsetParent(".dropdown-menu");
        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');
        $(this).parent("li").toggleClass('show');
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            $('.dropdown-menu .show').removeClass("show");
        });
        if (!$parent.parent().hasClass('navbar-nav')) {
            $el.next().css({
                "top": $el[0].offsetTop,
                "left": $parent.outerWidth() - 4
            });
        }
        return false;
    });
});
/*!
 * Bootstrap 4 動畫漢堡包導航員
 *
 */
$(function () {
    //toggle class open on button
    $('#navbarNavDropdown').on('hide.bs.collapse', function () {
        $('.navbar-toggler').removeClass('open');
    })
  $('#navbarNavDropdown').on('show.bs.collapse', function () {
    $('.navbar-toggler').addClass('open');
  })
});


var iCount = 0; //購物車的變數，用來增加購物車的數量的臨時變數
$(function () {
    $(".addcart").click(function () { //點選“加入購物車”觸發時事件
        iCount++; //點選一次就+1
        var addImg = $(this).parent().parent().parent().find(".project").find("img"); //找到該商品的圖片

        var cloneImg = addImg.clone(); //對該圖片進行克隆
        cloneImg.css({ //克隆的樣式 : ;
            "background-size": "cover",
            "width": "250px",
            "height": "auto",
            "position": "absolute", //絕對定位
            "left": addImg.offset().left, //該圖片的left位置
            "top": addImg.offset().top, //該圖片的top位置
            "z-index": "100", //層級，越大越在上
            "opacity": "0.5" //透明度  半透明
        });

        //克隆到body上的購物車位置
        cloneImg.appendTo($("body")).animate({
            "width": "50px", //克隆後的寬
            "height": "50px", //克隆後的寬
            "left": $("#dcar").offset().left, //克隆後的left位置  購物車
            "top": $("#dcar").offset().top, //克隆後的left位置  購物車
        }, 1000, function () { //克隆後

            $("#icon-cart").html(iCount); //購物車上的數 +1

            $("#msg").html("加入購物車成功 !").show().animate({
                width: '250px'
            }, 300).fadeOut(2000); ////成功加入購物車動畫效果

            $(this).remove(); //清空購物車  不清除圖片會疊加
        });
    });
});


//購物車
$(function () {
    function t() {
        var t = 0;
        $("#tab tbody tr").each(function () { //計算的範圍
            t += parseInt($(this).find("input[class*=num]").val()) * parseFloat($(this).find("b[class*=price]").text())
        }), $("#total").html(t.toFixed(0))
    }

    $("#tab input").attr("readonly", !0), $(".add").click(function () { //將input元素設置為只能讀取
        $(this).prev().val(parseInt($(this).prev().val()) + 1), t()
    }),
        $(".min").click(function () { //價錢
            num2 = $(this).next().val(), num2 > 1 && $(this).next().val(parseInt($(this).next().val()) - 1),
                console.log("價錢:", num2), t()
        }),
        $(".remove").click(function (n) { //移除
            n.preventDefault(), $(this).parent().parent().parent().remove(), t()
        }), t()
});
